* {
    box-sizing: border-box;
}

:root {
    --pt: 0.0625rem;
    --app-padding: 16px;
}

body {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

div.content {
    padding: var(--app-padding);
}

table {
    width: 100%;
    margin: 0;
    font-size: calc(35 * var(--pt));
    text-align: center;
}

td {
    padding-top: 16px;
}

tr:first-child > td {
    padding-top: 0;
}

.input-time {
    width: 2em;
    font-size: calc(35 * var(--pt));
}

.input-time > input {
    text-align: end;
    font-size: calc(35 * var(--pt));
}

td.label {
    text-align: start;
}

td.input {
    text-align: end;
}

td.rest-plus::before {
    content: "-";
    color: rgba(0, 0, 0, 0);
}

td.time {
    text-align: end;
}

.step-yet {
    color: black;
}

.step-current {
    color: darkred;
}

.step-done {
    color: grey;
}

.mdc-fab {
    position: fixed;
    bottom: 16px;
    right: 16px;
}

.no-recipe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-progress {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.32);
    z-index: 100;
    transform: scale(3);
}
